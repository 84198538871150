.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #1597F5;
  color: white;
  position: relative;
  font-family: 'Arial', sans-serif;
}

.auth-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.auth-button {
  background: transparent;
  border: 1px solid white;
  color: white;
  margin-left: 8px;
  padding: 10px 20px;
  cursor: pointer;
}

.slack-button {
  background: white;
  color: #1597F5;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

/* Add any additional styling you need here */
